import React from 'react';
import './App.css';

import DdosChart from './components/DdosChart';
import Title from './components/Title';
import Heading from './components/Heading'
import TrafficChart from './components/TrafficChart';
import DomainChart from './components/DomainChart';
import HeadingTooltip from './components/HeadingTooltip';

function App() {
  const [trafficChange, setTrafficChange] = React.useState(null)
  const [popularDomains, setPopularDomains] = React.useState(null)
  const [attacks, setAttacks] = React.useState(null)

  // get data
  React.useEffect(() => {
    fetch('https://worker.thecarsonharrell.workers.dev/popular-domains')
      .then((res) => res.json())
      .then((data) => { setPopularDomains(data) })
      .catch(err => { throw err })

    fetch('https://worker.thecarsonharrell.workers.dev/attack-layer3')
      .then((res) => res.json())
      .then((data) => { setAttacks(data) })
      .catch(err => { throw err })

    fetch('https://worker.thecarsonharrell.workers.dev/traffic-change')
      .then((res) => res.json())
      .then((data) => { setTrafficChange(data) })
      .catch(err => { throw err })
  }, [])


  return (
    <>
      <div class="App" style={{ width: "100%" }}>
        <Title />
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ marginLeft: 20 }}>
            <HeadingTooltip title="Traffic Change" info="This is the current total/http traffic change over the last 30 days" />
            <TrafficChart trafficChange={trafficChange} />
          </div>
          <div style={{ marginRight: 20 }}>
            <HeadingTooltip title="DDoS Attacks" info="This is the current volume of DDoS attacks over the last 30 days" />
            <DdosChart attacks={attacks} />
          </div>
        </div>
      </div>
      <div style={{
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        maxWidth: 600
      }}>
        <HeadingTooltip title="Popular Domains" info="This is a list of the most popular domains over the last 30 days" />
        <DomainChart popularDomains={popularDomains} />
      </div>
      <div style={{ padding: 30 }}></div>
    </>
  )
}

export default App;