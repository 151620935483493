import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown, faEquals } from '@fortawesome/free-solid-svg-icons'
function TableRow({ domain, rank, change }) {
    let icon;
    let color;
    if (change < 0) {
        icon = faArrowDown;
        color = 'rgb(255, 0, 0)';
    } else if (change > 0) {
        icon = faArrowUp;
        color = 'rgb(80, 200, 120)';
    } else {
        icon = null;
        color = 'black';
    }
    return (
        <tr>
            <td>
                <div class="link-hover">
                    <a class="remove-hover" target='_blank' href={'https://' + domain} style={{ textDecoration: "none" }}>
                        {domain}
                    </a>
                </div>
            </td>
            <td>{rank}</td>
            <td style={{ color: color }}> {change} {icon ? <FontAwesomeIcon icon={icon} /> : <></>}</td>
        </tr >
    )
}

export default TableRow;