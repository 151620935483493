import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Heading from './Heading';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

function HeadingTooltip({ title, info }) {
    return (
        <>
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">{title}</Typography>
                        {info}
                    </React.Fragment>
                }
            >
                <button><Heading title={title} /></button>
            </HtmlTooltip>
        </>
    )
}

export default HeadingTooltip;