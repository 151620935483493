import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { dateFormatter, dateFormatter2, fractionToPercent } from '../helpers';


function TrafficChart({ trafficChange }) {
    // get total for graph
    let total;
    if (trafficChange !== null) {
        try {
            total = trafficChange.data.total.objects;
        } catch (e) {
            console.log(e);
        }
    }

    // get http for graph
    let http;
    if (trafficChange !== null) {
        try {
            http = trafficChange.data.http.objects;
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <LineChart style={{ marginLeft: "auto", marginRight: "auto" }}
            width={500}
            height={300}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickFormatter={dateFormatter} dataKey="timestamp" xAxisId={"benchmark"} interval={"preserveStartEnd"} />
            <XAxis tickFormatter={dateFormatter} hide={true} dataKey="timestamp" xAxisId={"value"} interval={"preserveStartEnd"} />
            <YAxis tickFormatter={fractionToPercent}></YAxis>
            <Tooltip labelFormatter={dateFormatter2} formatter={fractionToPercent} />
            < Legend />
            <Line
                name="total traffic change"
                type="monotone"
                xAxisId={"value"}
                data={total}
                dataKey="value"
                stroke="#8884d8"
                dot={false}
            />
            <Line
                name="http traffic change"
                type="monotone"
                xAxisId={"benchmark"}
                data={http}
                dataKey="value"
                stroke="#FFC0CB"
                dot={false}
            />
        </LineChart>
    )
}

export default TrafficChart;