import TableRow from "./TableRow";

function DomainChart({ popularDomains }) {
    //create table for domain ranking
    const res = []
    if (popularDomains !== null) {
        var myDataSource = popularDomains.rankingEntries;

        myDataSource.forEach(element => {
            res.push(
                <TableRow
                    domain={element.domain}
                    rank={element.rank + 1}
                    change={element.rankChange}
                />
            )
        });
    }

    return (
        <table class="table table-hover">
            <thead>
                <th>Domain</th>
                <th>Rank</th>
                <th>30 Day Change</th>
            </thead>
            <tbody>{res}</tbody>
        </table>
    )
}

export default DomainChart;