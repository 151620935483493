import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { dateFormatter, dateFormatter2, fractionToPercent } from '../helpers';

function DdosChart({ attacks }) {
    let ddosVals;
    if (attacks !== null) {
        try {
            ddosVals = attacks.data.objects;
        } catch (e) {
            console.log(e);
        }
    }
    return (

        <LineChart style={{ marginLeft: "auto", marginRight: "auto" }}
            width={500}
            height={300}
            data={ddosVals}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <Tooltip labelFormatter={dateFormatter2} formatter={fractionToPercent} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="timestamp" tickFormatter={dateFormatter} />
            <YAxis tickFormatter={fractionToPercent} />
            <Legend />
            <Line name={"DDoS attack %"} type="monotone" dataKey={"value"} stroke="#004d80" dot={false} />
        </LineChart>
    )
}

export default DdosChart;