import * as moment from 'moment';

const dateFormatter = date => {
    // return moment(date).unix();
    return moment(date).format('MM/DD');
};

const dateFormatter2 = date => {
    // return moment(date).unix();
    return moment(date).format('MM/DD/YY HH:mm');
};

const fractionToPercent = fraction => {
    let percentNum = fraction * 100;
    percentNum = Math.round(percentNum * 10) / 10;
    return percentNum + '%';
}

export { dateFormatter, dateFormatter2, fractionToPercent };