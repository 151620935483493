function Heading({ title }) {
    return (
        <>
            <h3 style={{
                display: "inline-block"
            }} class="effect-underline">
                <a class="effect-underline">
                    {title}
                </a>
            </h3>
        </>
    );
}

export default Heading;